<ng-content select="app-table-row-cell"></ng-content>

<ng-container *ngIf="canToggle">
  <app-table-row-cell class="cell-toggle">
    <button class="button-icon button-toggle" [class.is-open]="isOpen" (click)="toggleDetails($event)">
      <span class="icon icon-chevron"></span>
    </button>
  </app-table-row-cell>

  <ng-container *ngIf="isOpen && detailsTemplate" [ngTemplateOutlet]="detailsTemplate"></ng-container>
</ng-container>
