import { UserAddEditFormModel } from '../../models/user-add-edit-form/user-add-edit-form.model';
import { UserFormDetailsDto } from '../../dtos/user-form-details/user-from-details.dto';

export class UserFormDetailsCreator {
  public static create(dto: UserFormDetailsDto): UserAddEditFormModel {
    return {
      email: dto.email,
      userName: dto.username,
      description: dto.description,
      contactPerson: dto.contact_person,
      phone: dto.phone,
      workshopId: dto.workshop_id,
      status: dto.status,
      blockageReason: dto.blockage_reason,
      role: dto.role,
      language: dto.language,
      supportedLanguages: dto.supported_languages,
    };
  }
}
