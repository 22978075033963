import { Component, EventEmitter, Input, Output } from '@angular/core';

import { AlertTypesModel } from '../../models/alert-types/alert-types.model';

@Component({
  selector: 'app-alert[type][textKey]',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  standalone: false,
})
export class AlertComponent {
  @Input() public type!: AlertTypesModel;
  @Input() public textKey!: string;
  @Input() public textButton: string | undefined;
  @Output() public buttonClick: EventEmitter<void>;

  constructor() {
    this.buttonClick = new EventEmitter();
  }

  public onButtonClick(): void {
    this.buttonClick.emit();
  }
}
