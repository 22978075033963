import { Component, EventEmitter, HostListener, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-table-row',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss'],
  standalone: false,
})
export class TableRowComponent {
  @Input() public canToggle: boolean;
  @Input() public detailsTemplate?: TemplateRef<unknown>;

  @Output() public detailsOpenStateChange: EventEmitter<boolean>;
  @Output() public rowSelected: EventEmitter<void>;

  public isOpen: boolean;

  constructor() {
    this.canToggle = false;
    this.isOpen = false;
    this.detailsOpenStateChange = new EventEmitter<boolean>();
    this.rowSelected = new EventEmitter<void>();
  }

  @HostListener('click')
  public emitSelected(): void {
    this.rowSelected.emit();
  }

  public toggleDetails(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.isOpen = !this.isOpen;

    this.emitDetailsOpenStateChange();
  }

  private emitDetailsOpenStateChange(): void {
    this.detailsOpenStateChange.emit(this.isOpen);
  }
}
