import { UserAddEditFormModel } from '../../models/user-add-edit-form/user-add-edit-form.model';
import { UserAddEditFormDto } from '../../dtos/user-add-edit-form/user-add-edit-form.dto';
import { UserAddEditDetailsFormDtoCreator } from '../user-add-edit-details-form-dto/user-add-edit-form-dto.creator';

export class UserAddEditFormDtoCreator {
  public static create(model: UserAddEditFormModel): UserAddEditFormDto {
    const userAddEditFormDto: UserAddEditFormDto = {
      email: model.email,
      workshop_id: model.workshopId,
      role: model.role,
      status: model.status,
      language: model.language,
      ...(model.blockageReason && { blockage_reason: model.blockageReason }),
      ...(model.supportedLanguages?.length && { supported_languages: model.supportedLanguages }),
    };

    if (model.userName || model.description || model.contactPerson || model.phone) {
      userAddEditFormDto.details = UserAddEditDetailsFormDtoCreator.create(model);
    }

    return userAddEditFormDto;
  }
}
