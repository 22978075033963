<div class="filters-box" [class.is-open]="isOpen">
  <div class="buttons-wrapper">
    <button class="button gray toggle-button" type="button" (click)="toggleIsOpen()">
      <span class="button-label">
        {{ 'shared.filtersBox.button.filters' | translate }}
      </span>

      <span class="icon icon-chevron-down-small-FFFFFF-18x18"></span>
    </button>

    <button class="button black-outline" type="button" (click)="clearFiltersClick()" *ngIf="hasClearFiltersButton">
      <span class="button-label">
        {{ 'shared.filtersBox.button.clearFilters' | translate }}
      </span>

      <span class="icon icon-close-525050-18x18"></span>
    </button>
  </div>

  <div class="buttons-wrapper">
    <ng-content select="[buttons]"></ng-content>
  </div>
</div>

<div class="filters-box-body" [ngbCollapse]="!isOpen" (ngbCollapseChange)="isOpen = $event">
  <ng-content select="[body]"></ng-content>
</div>
