import { WholesalersListDataPageModel } from '../../models/wholesalers-list-data-page/wholesalers-list-data-page.model';
import { WholesalersListDataPageDto } from '../../dtos/wholesalers-list-data-page/wholesalers-list-data-page.dto';

export class WholesalersListDataPageDtoCreator {
  public static create(model: WholesalersListDataPageModel): WholesalersListDataPageDto {
    return {
      ...(model.page && { page: model.page }),
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
    };
  }
}
