import { Component, Input } from '@angular/core';

import { StatusInfoEnum } from '../../enums/status-info/status-info.enum';
import { StatusInfoModel } from '../../models/status-info/status-info.model';

@Component({
  selector: 'app-status-info[type][title][message]',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss'],
  standalone: false,
})
export class StatusInfoComponent {
  @Input() public set type(statusInfoType: StatusInfoModel) {
    this.statusInfoType = statusInfoType || StatusInfoEnum.success;
  }
  @Input() public title!: string;
  @Input() public message!: string;

  public statusInfoType!: StatusInfoModel;
}
