import { WholesalersListItemDto } from '../../dtos/wholesalers-list-item/wholesalers-list-item.dto';
import { WholesalersListItemModel } from '../../models/wholesalers-list-item/wholesalers-list-item.model';

export class WholesalersListItemCreator {
  public static create(dto: WholesalersListItemDto): WholesalersListItemModel {
    return {
      id: dto.id,
      name: dto.name,
    };
  }
}
