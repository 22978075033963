import { AfterContentChecked, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-table-row-cell',
  templateUrl: './table-row-cell.component.html',
  styleUrls: ['./table-row-cell.component.scss'],
  standalone: false,
})
export class TableRowCellComponent implements AfterContentChecked {
  @ViewChild('tableCellContentWrapper') public tableCellContentWrapperElementRef?: ElementRef<HTMLElement>;

  public tooltipText: string;
  public isTooltipDisabled: boolean;
  public isTextTruncated: boolean;
  public tooltipOpenDelay: number;

  private parentClientWidth: number = 0;
  private scrollWidth: number = 0;

  constructor() {
    this.tooltipText = '';
    this.isTooltipDisabled = false;
    this.isTextTruncated = false;
    this.tooltipOpenDelay = 1000;
  }

  public ngAfterContentChecked(): void {
    this.setIsTextTruncated();
    this.setTooltipText();
    this.setIsTooltipDisabled();
  }

  private setTooltipText(): void {
    this.tooltipText = this.tableCellContentWrapperElementRef?.nativeElement.textContent || '';
  }

  private setParentClientWidth(): void {
    this.parentClientWidth = this.tableCellContentWrapperElementRef?.nativeElement.parentElement?.clientWidth || 0;
  }

  private setScrollWidth(): void {
    this.scrollWidth = this.tableCellContentWrapperElementRef?.nativeElement.scrollWidth || 0;
  }

  private setIsTextTruncated(): void {
    this.setScrollWidth();
    this.setParentClientWidth();

    this.isTextTruncated = this.parentClientWidth < this.scrollWidth + 20;
  }

  private setIsTooltipDisabled(): void {
    this.isTooltipDisabled = !this.isTextTruncated;
  }
}
