import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  QueryList,
  ViewChild,
} from '@angular/core';

import { interval, Subscription } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';

import { TableRowComponent } from '../table-row/table-row.component';
import { TableHeaderComponent } from '../table-header/table-header.component';
import { NoResultsOptionsModel } from '../../models/no-results/no-results-options.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  standalone: false,
})
export class TableComponent implements AfterContentChecked, AfterContentInit, OnDestroy {
  @Input() public showNoResults: boolean;
  @Input() public showLoader: boolean;
  @Input() public isScrollEnabled: boolean;
  @Input() public noResultsOptions?: NoResultsOptionsModel;

  @ViewChild('table', { static: true }) public tableElement!: ElementRef<HTMLElement>;
  @ViewChild('tableHeader', { static: true }) public tableHeaderElement!: ElementRef<HTMLElement>;
  @ViewChild('tableContent', { static: false }) public tableContentElement!: ElementRef<HTMLElement>;
  @ViewChild(NgScrollbar, { static: false }) public scrollBar!: NgScrollbar;

  @ContentChildren(TableHeaderComponent) public tableHeaders?: QueryList<TableHeaderComponent>;
  @ContentChildren(TableRowComponent) public tableRows?: QueryList<TableRowComponent>;

  public canToggle: boolean;

  private tableResizeObserver!: ResizeObserver;
  private tableSizeInterval: number;
  private tableSizeIntervalSubscription: Subscription | null;

  constructor(private ngZone: NgZone) {
    this.canToggle = false;
    this.showLoader = false;
    this.showNoResults = false;
    this.isScrollEnabled = true;
    this.tableSizeInterval = 50;
    this.tableSizeIntervalSubscription = null;
  }

  public ngOnDestroy(): void {
    if (this.tableResizeObserver) {
      this.tableResizeObserver.unobserve(this.tableElement.nativeElement);
    }

    if (this.tableSizeIntervalSubscription) {
      this.tableSizeIntervalSubscription.unsubscribe();
    }
  }

  public ngAfterContentChecked(): void {
    this.initCanToggleStateForHeader();
  }

  public ngAfterContentInit(): void {
    this.initTableResizeObserver();
    this.initTableSize();
  }

  private initTableResizeObserver(): void {
    this.tableResizeObserver = new ResizeObserver(() => {
      this.resizeTableHeaderCellSizeOutsideAngular();
    });

    this.tableResizeObserver.observe(this.tableElement.nativeElement);
  }

  private initTableSize(): void {
    this.tableSizeIntervalSubscription = interval(this.tableSizeInterval).subscribe(() => {
      if (this.hasNotTableHeaderOrContentCells()) {
        return;
      }

      this.resizeTableHeaderCellSizeOutsideAngular();
      this.tableSizeIntervalSubscription?.unsubscribe();
    });
  }

  private initCanToggleStateForHeader(): void {
    if (!this.tableRows?.length) {
      return;
    }

    this.canToggle = this.tableRows.some((tableRow: TableRowComponent) => {
      return tableRow.canToggle;
    });

    this.initCanToggleForTableHeader();
  }

  private initCanToggleForTableHeader(): void {
    if (!this.tableHeaders?.length) {
      return;
    }

    this.tableHeaders.forEach((tableHeader: TableHeaderComponent) => {
      tableHeader.canToggle = this.canToggle;
    });
  }

  private resizeTableHeaderCellSize(): void {
    if (this.hasNotTableHeaderOrContentCells()) {
      return;
    }

    const tableHeaderRowElement: Element | null = this.getTableHeaderRowElement();
    const tableContentRowElement: Element | null = this.getTableContentRowElement();
    const tableHeaderCells: HTMLCollection = (tableHeaderRowElement as Element).getElementsByTagName('app-table-header-cell');
    const tableRowCells: HTMLCollection = (tableContentRowElement as Element).getElementsByTagName('app-table-row-cell');

    for (let i: number = 0; i < tableRowCells.length; i++) {
      this.setTableHeaderCellSizeByRowCell(tableHeaderCells[i], tableRowCells[i]);
    }
  }

  private resizeTableHeaderCellSizeOutsideAngular(): void {
    this.ngZone.runOutsideAngular(() => {
      this.resizeTableHeaderCellSize();
    });
  }

  private getTableHeaderRowElement(): Element | null {
    return this.tableHeaderElement.nativeElement.children.item(0);
  }

  private getTableContentRowElement(): Element | null {
    return this.tableContentElement.nativeElement.children.item(0);
  }

  private hasNotTableHeaderOrContentCells(): boolean {
    const tableHeaderRowElement: Element | null = this.getTableHeaderRowElement();
    const tableContentRowElement: Element | null = this.getTableContentRowElement();

    return (
      !tableHeaderRowElement || !tableHeaderRowElement.children.length || !tableContentRowElement || !tableContentRowElement.children.length
    );
  }

  private clearTableHeaderCellSize(tableHeaderCell: Element): void {
    tableHeaderCell.setAttribute('style', '');
  }

  private setTableHeaderCellSizeByRowCell(tableHeaderCell: Element, tableRowCellElement: Element): void {
    this.clearTableHeaderCellSize(tableHeaderCell);

    const tableRowCellWidth: DOMRect = tableRowCellElement.getBoundingClientRect();

    tableHeaderCell.setAttribute('style', `width: ${tableRowCellWidth.width}px`);
  }
}
