import { UsersListDataPageDto } from '../../dtos/users-list-data-page/users-list-data-page.dto';
import { UsersListDataPageModel } from '../../models/users-list-data-page/users-list-data-page.model';
import { DateUtil } from '../../../shared/utils/date/date.util';

export class UsersListDataPageDtoCreator {
  public static create(model: UsersListDataPageModel): UsersListDataPageDto {
    return {
      page: model.page,
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      ...(model.workshop && { workshops: model.workshop.split(',') }),
      ...(model.email && { email: model.email }),
      ...(model.userName && { username: model.userName }),
      ...(model.status && { statuses: model.status.split(',') }),
      ...(model.createdAtFrom && { created_at_from: DateUtil.convertToApiFormat(model.createdAtFrom) }),
      ...(model.createdAtTo && { created_at_to: DateUtil.convertToApiFormat(model.createdAtTo, true) }),
      ...(model.updatedAtFrom && { updated_at_from: DateUtil.convertToApiFormat(model.updatedAtFrom) }),
      ...(model.updatedAtTo && { updated_at_to: DateUtil.convertToApiFormat(model.updatedAtTo, true) }),
      ...(model.loggedAtFrom && { logged_at_from: DateUtil.convertToApiFormat(model.loggedAtFrom) }),
      ...(model.loggedAtTo && { logged_at_to: DateUtil.convertToApiFormat(model.loggedAtTo, true) }),
      ...(model.role && { roles: model.role.split(',') }),
      ...(model.wholesalers && { wholesalers: model.wholesalers.split(',') }),
    };
  }
}
