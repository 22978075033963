import { Component, Input, OnInit } from '@angular/core';

// @ts-ignore
import RedactorX from '../../../../assets/vendors/redactorx-1-4-6/redactorx.usm.min.js';
import { RedactorXService } from '../../services/redactor-x/redactor-x.service';
import { SubscriptionStoreComponent } from '../subscription-store/subscription-store.component';

@Component({
  selector: 'app-redactor-x[id]',
  templateUrl: './redactor-x.component.html',
  styleUrls: ['./redactor-x.component.scss'],
  standalone: false,
})
export class RedactorXComponent extends SubscriptionStoreComponent implements OnInit {
  @Input() public id!: string;
  @Input() public options: object;

  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/typedef
  public app;

  constructor(private redactorXService: RedactorXService) {
    super();

    this.options = {};
  }

  public ngOnInit(): void {
    this.initRedactorX();
  }

  public getContent(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    return this.app.editor.getContent();
  }

  public setContent(content: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.app.editor.setContent({ html: content });
  }

  public setEmpty(): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    this.app.editor.setEmpty();
  }

  private initRedactorX(): void {
    this.subscription = this.redactorXService.initStylesheetAndScripts().subscribe(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.app = RedactorX(`#${this.id}`, this.options);
    });
  }
}
