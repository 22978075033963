import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiUrlUtil } from '../../../core/utils/api-url/api-url.util';
import { ApiResponseDto } from '../../../core/dtos/api-response/api-response.dto';
import { CurrentUserDto } from '../../dtos/current-user/current-user.dto';
import { ListDto } from '../../../shared/dtos/list/list.dto';
import { UsersListItemDto } from '../../dtos/users-list-item/users-list-item.dto';
import { UsersListDataPageDto } from '../../dtos/users-list-data-page/users-list-data-page.dto';
import { UsersListItemDetailsDto } from '../../dtos/users-list-item-details/users-list-item-details.dto';
import { UserAddEditFormDto } from '../../dtos/user-add-edit-form/user-add-edit-form.dto';
import { QueryParamsUtil } from '../../../shared/utils/query-params/query-params.util';
import { UserFormDetailsDto } from '../../dtos/user-form-details/user-from-details.dto';
import { WholesalersListItemDto } from '../../dtos/wholesalers-list-item/wholesalers-list-item.dto';
import { WholesalersListDataPageDto } from '../../dtos/wholesalers-list-data-page/wholesalers-list-data-page.dto';
import { UserSupportedLanguagesDataPageDto } from '../../dtos/user-supported-languages-data-page/user-supported-languages-data-page.dto';
import { UserSupportedLanguagesDropdownMultiselectOptionListDto } from '../../dtos/user-supported-languages-dropdown-multiselect-option-list/user-supported-languages-dropdown-multiselect-option-list.dto';

@Injectable({
  providedIn: 'root',
})
export class UsersHttpService {
  constructor(private httpClient: HttpClient) {}

  public getCurrentUser(): Observable<CurrentUserDto> {
    return this.httpClient
      .get<ApiResponseDto<CurrentUserDto>>(ApiUrlUtil.getApiUrl('security/me'))
      .pipe(map((response: ApiResponseDto<CurrentUserDto>) => response.data));
  }

  public getUsersList(usersListRequestDto: UsersListDataPageDto): Observable<ListDto<UsersListItemDto>> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<UsersListDataPageDto>(usersListRequestDto);

    return this.httpClient
      .get<ApiResponseDto<ListDto<UsersListItemDto>>>(ApiUrlUtil.getApiUrl('user/users'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<UsersListItemDto>>) => response.data));
  }

  public getUserDetails(userId: string): Observable<UsersListItemDetailsDto> {
    return this.httpClient
      .get<ApiResponseDto<UsersListItemDetailsDto>>(ApiUrlUtil.getApiUrl(`user/users/${userId}`))
      .pipe(map((response: ApiResponseDto<UsersListItemDetailsDto>) => response.data));
  }

  public getUserFormDetails(userId: string): Observable<UserFormDetailsDto> {
    return this.httpClient
      .get<ApiResponseDto<UserFormDetailsDto>>(ApiUrlUtil.getApiUrl(`user/users/${userId}/form-details`))
      .pipe(map((response: ApiResponseDto<UserFormDetailsDto>) => response.data));
  }

  public addUser(user: UserAddEditFormDto): Observable<void> {
    return this.httpClient.post<void>(ApiUrlUtil.getApiUrl('user/users'), user);
  }

  public updateUserDetails(user: UserAddEditFormDto, userId: string): Observable<void> {
    return this.httpClient.put<void>(ApiUrlUtil.getApiUrl(`user/users/${userId}`), user);
  }

  public deleteUser(userId: string): Observable<void> {
    return this.httpClient.delete<void>(ApiUrlUtil.getApiUrl(`user/users/${userId}`));
  }

  public getWholesalersList(wholesalersListFiltersDto: WholesalersListDataPageDto): Observable<ListDto<WholesalersListItemDto>> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<WholesalersListDataPageDto>(wholesalersListFiltersDto);

    return this.httpClient
      .get<ApiResponseDto<ListDto<WholesalersListItemDto>>>(ApiUrlUtil.getApiUrl('user/dictionaries/wholesalers'), { params })
      .pipe(map((response: ApiResponseDto<ListDto<WholesalersListItemDto>>) => response.data));
  }

  public getUserSupportedLanguages(
    userSupportedLanguagesDataPage: UserSupportedLanguagesDataPageDto
  ): Observable<UserSupportedLanguagesDropdownMultiselectOptionListDto> {
    const params: HttpParams = QueryParamsUtil.mapFiltersToHttpParams<UserSupportedLanguagesDataPageDto>(userSupportedLanguagesDataPage);

    return this.httpClient
      .get<ApiResponseDto<UserSupportedLanguagesDropdownMultiselectOptionListDto>>(ApiUrlUtil.getApiUrl('user/languages/supported'), {
        params,
      })
      .pipe(map((response: ApiResponseDto<UserSupportedLanguagesDropdownMultiselectOptionListDto>) => response.data));
  }
}
