import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, NgZone } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ToastModel } from '../../models/toast/toast.model';
import { ToastsComponent } from '../../components/toasts/toasts.component';
import { ToastType } from '../../models/toast-type/toast-type.model';

@Injectable({
  providedIn: 'root',
})
export class ToastsService {
  private toasterContainerRef?: ComponentRef<ToastsComponent>;
  private toasts$: BehaviorSubject<Array<ToastModel>>;
  private readonly toastDelay: number;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private ngZone: NgZone,
    private injector: Injector
  ) {
    this.toasts$ = new BehaviorSubject<Array<ToastModel>>([]);
    this.toastDelay = 3000;
  }

  public getToasts(): Observable<Array<ToastModel>> {
    return this.toasts$.asObservable();
  }

  public showSuccess(message: string, delay: number = this.toastDelay): void {
    this.showToast(this.getToast(message, delay, 'success'));
  }

  public showError(message: string, delay: number = this.toastDelay): void {
    this.showToast(this.getToast(message, delay, 'error'));
  }

  public remove(toast: ToastModel): void {
    let toasts: Array<ToastModel> = this.toasts$.getValue();

    this.ngZone.runOutsideAngular(() => {
      toasts = toasts.filter((currentToast: ToastModel) => currentToast !== toast);

      this.toasts$.next(toasts);

      if (!toasts.length) {
        setTimeout(() => {
          this.removeToastsView();
        }, 200);
      }
    });
  }

  private removeToastsView(): void {
    this.ngZone.run(() => {
      if (this.toasterContainerRef) {
        this.appRef.detachView(this.toasterContainerRef.hostView);
        this.toasterContainerRef.destroy();
        this.toasterContainerRef = undefined;
      }
    });
  }

  private createToastsView(): void {
    this.toasterContainerRef = this.componentFactoryResolver.resolveComponentFactory(ToastsComponent).create(this.injector);

    this.appRef.attachView(this.toasterContainerRef.hostView);

    const domElement: HTMLElement = this.toasterContainerRef.location.nativeElement as HTMLElement;

    document.body.appendChild(domElement);
  }

  private getToast(message: string, delay: number, className: ToastType): ToastModel {
    return {
      message,
      options: {
        delay,
        type: className,
      },
    };
  }

  private showToast(toast: ToastModel): void {
    const toasts: Array<ToastModel> = this.toasts$.getValue();

    this.ngZone.runOutsideAngular(() => {
      if (!this.toasterContainerRef) {
        this.createToastsView();
      }

      toasts.push(toast);

      this.toasts$.next(toasts);
    });
  }
}
