<div class="form-footer">
  <div>
    <span>(</span>
    <span class="required-mark">*</span>
    <span>)</span>
    <span>&nbsp;{{ 'common.message.requiredFields' | translate }}</span>
  </div>

  <ng-content select="[form-buttons]"></ng-content>
</div>
