import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ToastsComponent } from './components/toasts/toasts.component';

@NgModule({
  declarations: [ToastsComponent],
  imports: [CommonModule, NgbToastModule, TranslateModule],
  exports: [ToastsComponent],
})
export class ToastsModule {}
