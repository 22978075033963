import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UsersService } from '../users/users.service';
import { CurrentUserModel } from '../../models/current-user/current-user.model';
import { UserRoleUuidModel } from '../../models/user-role-uuid/user-role-uuid.model';
import { PredefinedUserRolesUuidsEnum } from '../../enums/predefined-user-roles-uuids/predefined-user-roles-uuids.enum';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private currentUser$: BehaviorSubject<CurrentUserModel | null>;

  constructor(private usersService: UsersService) {
    this.currentUser$ = new BehaviorSubject<CurrentUserModel | null>(null);
  }

  public getCurrentUser(): Observable<CurrentUserModel | null> {
    return this.currentUser$.asObservable();
  }

  public getCurrentUserUsername(): Observable<string | null> {
    return this.currentUser$.pipe(map((user: CurrentUserModel | null) => user?.username || null));
  }

  public getCurrentUserRoleUuid(): Observable<UserRoleUuidModel | null> {
    return this.currentUser$.pipe(map((user: CurrentUserModel | null) => user?.role || null));
  }

  public getCurrentUserRoleIsAdmin(): boolean {
    const currentUser: CurrentUserModel | null = this.currentUser$.getValue();

    return currentUser?.role === PredefinedUserRolesUuidsEnum.roleAdmin;
  }

  public updateCurrentUser(): Observable<void> {
    return this.usersService.getCurrentUser().pipe(
      tap((currentUser: CurrentUserModel) => {
        this.setCurrentUser(currentUser);
      }),
      map(() => void 0)
    );
  }

  public clearCurrentUser(): void {
    this.currentUser$.next(null);
  }

  private setCurrentUser(currentUser: CurrentUserModel): void {
    this.currentUser$.next(currentUser);
  }
}
