import { UserSupportedLanguageListItemDto } from '../../dtos/user-supported-language-list-item/user-supported-language-list-item.dto';
import { UserSupportedLanguageListItemModel } from '../../models/user-supported-language-list-item/user-supported-language-list-item.model';

export class UserSupportedLanguageListItemCreator {
  public static create(dto: UserSupportedLanguageListItemDto): UserSupportedLanguageListItemModel {
    return {
      code: dto.code,
      name: dto.name,
    };
  }
}
