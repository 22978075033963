import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { debounceTime } from 'rxjs/operators';

import { SubscriptionStoreComponent } from '../subscription-store/subscription-store.component';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  standalone: false,
})
export class SearchBoxComponent extends SubscriptionStoreComponent implements OnInit {
  @Input() public set searchQuery(query: string | undefined) {
    this.setCurrentSearchQuery(query);
  }
  @Input() public placeholder: string;

  @Output() public searchQueryChange: EventEmitter<string>;

  public searchBoxControl: UntypedFormControl;

  private readonly searchQueryChangeDebounceTime: number;

  constructor() {
    super();

    this.placeholder = 'shared.searchBox.input.placeholder.search';
    this.searchQueryChange = new EventEmitter<string>();
    this.searchBoxControl = new UntypedFormControl();
    this.searchQueryChangeDebounceTime = 350;
  }

  public ngOnInit(): void {
    this.listenToSearchQueryFormChanges();
  }

  private setCurrentSearchQuery(query?: string): void {
    this.setSearchBoxControlValue(query);
  }

  private setSearchBoxControlValue(query?: string): void {
    this.searchBoxControl.setValue(query);
  }

  private listenToSearchQueryFormChanges(): void {
    this.subscription = this.searchBoxControl.valueChanges.pipe(debounceTime(this.searchQueryChangeDebounceTime)).subscribe(() => {
      this.emitSearchQuery();
    });
  }

  private emitSearchQuery(): void {
    this.searchQueryChange.emit(this.searchBoxControl.value as string);
  }
}
