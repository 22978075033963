import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { fromEvent, map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedactorXService {
  private readonly redactorXStylesheetSrc: string;
  private readonly redactorXScriptSrc: string;
  private readonly redactorXLangsScriptSrc: string;
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected rendererFactory: RendererFactory2
  ) {
    this.redactorXStylesheetSrc = 'assets/vendors/redactorx-1-4-6/redactorx.min.css';
    this.redactorXScriptSrc = 'assets/vendors/redactorx-1-4-6/redactorx.min.js';
    this.redactorXLangsScriptSrc = 'assets/js/redactorx/translations.js';
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public initStylesheetAndScripts(): Observable<void> {
    return this.loadRedactorXStylesheet().pipe(
      switchMap(() => this.loadRedactorXLangsScript()),
      switchMap(() => this.loadRedactorXScript())
    );
  }

  private loadRedactorXStylesheet(): Observable<void> {
    const linkElement: HTMLLinkElement = this.loadStylesheet(this.redactorXStylesheetSrc);

    return fromEvent(linkElement, 'load').pipe(map(() => void 0));
  }

  private loadRedactorXScript(): Observable<void> {
    const scriptElement: HTMLScriptElement = this.loadScript(this.redactorXScriptSrc);

    return fromEvent(scriptElement, 'load').pipe(map(() => void 0));
  }

  private loadRedactorXLangsScript(): Observable<void> {
    const scriptElement: HTMLScriptElement = this.loadScript(this.redactorXLangsScriptSrc, 'module');

    return fromEvent(scriptElement, 'load').pipe(map(() => void 0));
  }

  private loadStylesheet(stylesheetSrc: string): HTMLLinkElement {
    const link: HTMLLinkElement = this.renderer.createElement('link');

    link.rel = 'stylesheet';
    link.href = stylesheetSrc;

    this.addLinkToHead(link);

    return link;
  }

  private loadScript(scriptSrc: string, scriptType: string = 'text/javascript'): HTMLScriptElement {
    const script: HTMLScriptElement = this.renderer.createElement('script');

    script.type = scriptType;
    script.src = scriptSrc;

    this.addScriptToBody(script);

    return script;
  }

  private addLinkToHead(link: HTMLLinkElement): void {
    this.renderer.appendChild(this.document.head, link);
  }

  private addScriptToBody(script: HTMLScriptElement): void {
    this.renderer.appendChild(this.document.body, script);
  }
}
