import { UserSupportedLanguagesDataPageDto } from '../../dtos/user-supported-languages-data-page/user-supported-languages-data-page.dto';
import { UserSupportedLanguagesDataPageModel } from '../../models/user-supported-languages-data-page/user-supported-languages-data-page.model';

export class UserSupportedLanguagesDataPageDtoCreator {
  public static create(model: UserSupportedLanguagesDataPageModel): UserSupportedLanguagesDataPageDto {
    return {
      ...(model.page && { page: model.page }),
      ...(model.itemsPerPage && { limit: model.itemsPerPage }),
      ...(model.include?.length && { include: model.include }),
    };
  }
}
