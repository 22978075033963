import { Component } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss'],
  standalone: false,
})
export class TableHeaderComponent {
  public canToggle: boolean;

  constructor() {
    this.canToggle = false;
  }
}
