import { UsersListItemModel } from '../../models/users-list-item/users-list-item.model';
import { UsersListItemDto } from '../../dtos/users-list-item/users-list-item.dto';
import { UserStatusEnum } from '../../enums/user-status/user-status.enum';

export class UsersListItemCreator {
  public static create(dto: UsersListItemDto): UsersListItemModel {
    return {
      id: dto.id,
      workshop: dto.workshop,
      email: dto.email,
      userName: dto.username,
      role: dto.role,
      status: dto.status,
      statusRaw: dto.status_raw,
      wholesaler: dto.wholesaler,
      isActive: dto.status_raw === UserStatusEnum.active,
      isBlocked: dto.status_raw === UserStatusEnum.blocked,
    };
  }
}
