import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { NgbCollapseModule, NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { LottieComponent } from 'ngx-lottie';

import { SubscriptionStoreComponent } from './components/subscription-store/subscription-store.component';
import { StatusInfoComponent } from './components/status-info/status-info.component';
import { TableComponent } from './components/table/table.component';
import { TableRowDetailsComponent } from './components/table-row-details/table-row-details.component';
import { TableHeaderCellComponent } from './components/table-header-cell/table-header-cell.component';
import { TableRowCellComponent } from './components/table-row-cell/table-row-cell.component';
import { TableRowComponent } from './components/table-row/table-row.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingDirective } from './directives/loading/loading.directive';
import { NoResultsComponent } from './components/no-results/no-results.component';
import { ToastsModule } from '../toasts/toast.module';
import { FiltersBoxComponent } from './components/filters-box/filters-box.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { FormFooterComponent } from './components/form-footer/form-footer.component';
import { ChipsComponent } from './components/chips/chips.component';
import { ButtonWithLoaderComponent } from './components/button-with-loader/button-with-loader.component';
import { RedactorXComponent } from './components/redactor-x/redactor-x.component';
import { AlertComponent } from './components/alert/alert.component';

@NgModule({
  declarations: [
    FiltersBoxComponent,
    LoaderComponent,
    LoadingDirective,
    NoResultsComponent,
    TableComponent,
    TableRowComponent,
    TableRowCellComponent,
    TableRowDetailsComponent,
    TableHeaderComponent,
    TableHeaderCellComponent,
    SubscriptionStoreComponent,
    StatusInfoComponent,
    SearchBoxComponent,
    FormFooterComponent,
    ChipsComponent,
    ButtonWithLoaderComponent,
    RedactorXComponent,
    AlertComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgScrollbarModule,
    TranslateModule,
    RouterModule,
    NgbDropdownModule,
    NgbTooltipModule,
    ToastsModule,
    LottieComponent,
  ],
  exports: [
    FiltersBoxComponent,
    LoaderComponent,
    LoadingDirective,
    NoResultsComponent,
    TableComponent,
    TableRowComponent,
    TableRowCellComponent,
    TableRowDetailsComponent,
    TableHeaderComponent,
    TableHeaderCellComponent,
    StatusInfoComponent,
    SearchBoxComponent,
    ToastsModule,
    FormFooterComponent,
    ChipsComponent,
    ButtonWithLoaderComponent,
    RedactorXComponent,
    AlertComponent,
  ],
})
export class SharedModule {}
