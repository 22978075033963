import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  standalone: false,
})
export class ModalComponent implements OnInit {
  @Input() public hasContentSidePadding: boolean;
  @Input() public hasCloseIconButton: boolean;

  @ViewChild('modal', { static: true }) public modalElement!: ElementRef<HTMLElement>;
  @ViewChild('titleWrapper', { static: true }) public titleWrapper?: ElementRef<HTMLElement>;
  @ViewChild('footerWrapper', { static: true }) public footerWrapper?: ElementRef<HTMLElement>;

  public isHeader: boolean;
  public isFooter: boolean;

  constructor(private activeModal: NgbActiveModal) {
    this.isHeader = false;
    this.isFooter = false;
    this.hasContentSidePadding = true;
    this.hasCloseIconButton = true;
  }

  public ngOnInit(): void {
    this.isHeader = !!this.titleWrapper?.nativeElement.childNodes.length;
    this.isFooter = !!this.footerWrapper?.nativeElement.childNodes.length;
  }

  public closeModal(): void {
    this.activeModal.close(false);
  }
}
