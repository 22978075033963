import { ListPaginationCreator } from '../list-pagination/list-pagination.creator';
import { DictionaryListDto } from '../../dtos/dictionary-list/dicionary-list.dto';
import { DictionaryListModel } from '../../models/dictionary-list/dictionary-list.model';

export class DictionaryListCreator {
  public static create<DTO, MODEL>(dto: DictionaryListDto<DTO>, include: Array<MODEL>, items: Array<MODEL>): DictionaryListModel<MODEL> {
    return {
      items,
      include,
      pagination: ListPaginationCreator.create(dto),
    };
  }
}
